import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Vision from '../pages/Vision'
import DFit from '../pages/DFit'
import PressBook from '../pages/PressBook'
import Contact from '../pages/Contact'
import Blog from '../pages/Blog'
import About from '../pages/About'
import Galerie from '../pages/Galerie'
import Activity from '../pages/Activity'
import Policy from '../pages/Policy'
import Conditions from '../pages/Conditions'

function Router() {
  return (
    <div className="no-js">
        <BrowserRouter>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/home' element={<Home />} />
              <Route path='/a-propos' element={<About />} />
              <Route path='/galerie' element={<Galerie />} />
              <Route path='/activites' element={<Activity />} />
              {/* <Route path='/our-vision' element={<Vision />} /> */}
              <Route path='/press-book' element={<PressBook />} />
              <Route path='/d-fit' element={<DFit />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/politique-confidentialite' element={<Policy />} />
              <Route path='/conditions-generales' element={<Conditions />} />
            </Routes>
        </BrowserRouter>
    </div>
  )
}

export default React.memo(Router)
