import React from 'react'
import Banner from './Banner'
import BgVideo from './BgVideo'
import BlogList from './BlogList'
import Sponsors from './Sponsors'
import Pricing from './Pricing'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const items = [
    "/assets/img/gallery_1.jpg",
    "/assets/img/gallery_2.jpg",
    "/assets/img/gallery_3.jpg",
    "/assets/img/gallery_4.jpg",
    "/assets/img/gallery_1.jpg",
    "/assets/img/gallery_2.jpg",
    "/assets/img/gallery_3.jpg",
    "/assets/img/gallery_4.jpg",
    "/assets/img/gallery_1.jpg",
    "/assets/img/gallery_2.jpg",
    "/assets/img/gallery_3.jpg",
    "/assets/img/gallery_4.jpg",
    "/assets/img/gallery_1.jpg",
    "/assets/img/gallery_2.jpg",
    "/assets/img/gallery_3.jpg",
    "/assets/img/gallery_4.jpg"
]

function PressBookContainer() {
    return (
        <main>
            {/* <!-- banner-section --> */}
            <Banner title="Press Book" subtitle="Press Book" />

            {/* <!-- blog-area --> */}
            <BlogList />
        </main>
    )
}

export default PressBookContainer
