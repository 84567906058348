import React from 'react'
import BgVideo from './BgVideo'

function Sponsors() {
    return (
        <div>
            <section class="sponsors services-bg pt-113 fix">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-12 col-lg-12">
                            <div class="section-title mb-80">
                                <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Ils nous ont fait confiance</span>
                                <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Nos Partenaires</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 text-center">
                            <ul class="wow fadeInDown animated" 
                                data-animation="fadeInDown animated" 
                                data-delay=".2s"
                            >
                                <li><img src="/assets/img/partners/theplug com.png" alt="THEPLUG COM" /></li>
                                <li><img src="/assets/img/partners/Santé.jpg" alt="MinSante" /></li>
                                <li><img src="/assets/img/partners/Bold make up.png" alt="Bold Make Up" /></li>
                                <li><img src="/assets/img/partners/fabafriq.png" alt="Fab Afriq" /></li>
                                <li><img src="/assets/img/partners/julia samantha.png" alt="Julia Samantha" /></li>
                                <li><img src="/assets/img/partners/mmmh.png" alt="Mmmh" /></li>
                                <li><img src="/assets/img/partners/ndock bidi.png" alt="Ndock Bidi" /></li>
                                <li><img src="/assets/img/partners/tara sports.png" alt="Tara Sports" /></li>
                                <li><img src="/assets/img/partners/yenga africa.png" alt="Yengafrica" /></li>
                                <li><img src="/assets/img/partners/quality_pro_consulting.jpg" alt="Quality Pro Consulting" /></li>
                                <li><img src="/assets/img/partners/checkup.jpg" alt="Checkup" /></li>
                                <li><img src="/assets/img/partners/terrific_coffee.jpg" alt="Terrific Coffee" /></li>
                                <li><img src="/assets/img/partners/communaute_urbaine.jpg" alt="Communauté Urbaine" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section class="sponsors services-bg pt-113 fix">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-12 col-lg-12">
                            <div class="section-title mb-80">
                                <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Ils nous ont fait confiance</span>
                                <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Nos Sponsors</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 text-center">
                            <ul class="wow fadeInDown animated" 
                                data-animation="fadeInDown animated" 
                                data-delay=".2s"
                            >
                                <li><img src="/assets/img/partners/DFIT.jpg" alt="DFIT" /></li>
                                <li><img src="/assets/img/partners/theplug com.png" alt="THEPLUG" /></li>
                                <li><img src="/assets/img/sponsors/logo_bold_rond blanc.png" alt="Bold Make Up" /></li>
                                <li><img src="/assets/img/sponsors/tap_tap_send.jpg" alt="Tap Tap Send" /></li>
                                <li><img src="/assets/img/sponsors/eau_vitale.jpg" alt="Eau Vitale" /></li>
                                <li><img src="/assets/img/sponsors/world_cola.jpg" alt="World Cola" /></li>
                                <li><img src="/assets/img/sponsors/yummy_greens.jpg" alt="Yummy Greens" /></li>
                                {/* <li><img src="/assets/img/sponsors/women_impact_days.jpg" alt="Women Impact Days" /></li>
                                <li><img src="/assets/img/sponsors/falocam.png" alt="Falocam" /></li> */}
                                <li><img src="/assets/img/sponsors/body_care_complex.jpg" alt="Body Care Complex" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Sponsors
