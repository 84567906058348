import React, { useEffect, useState } from 'react'

function ProgramsEvent() {
	const [events, setEvents] = useState([])

	useEffect(() => {
		const data = programs.filter(e => e.day === "day-one")
		setEvents(data)
	}, [])

	const programs = [
		{
			day: 'day-one',
			events: [
				{
					time: '9h - 10h',
					intitule: "Installation des invités"
				},
				{
					time: '10h - 10h30',
					intitule: "Mot de bienvenu de la promotrice INGRID NGOUNE",
					description: ["INGRID NGOUNE PROMOTRICE de D-FIT centre de nutrition santé et sport et de SAB (Salon de l'Alimentation et du Bien-être"]
				},
				{
					time: '10h45 - 11h20',
					intitule: "Mot de bienvenu de la marraine (Mme Rosette IVHA DIBOUA)"
				},
				{
					time: '11h20 - 12h',
					intitule: "Mot de bienvenu d'un sponsor"
				},
				{
					time: '12h - 12h30',
					intitule: "Mot de bienvenu d'un partenaire (Vanessa Tabeth)",
					description: ["Vanessa Tabeth promotrice de Mmmh la Crêperie"]
				},
				{
					time: '12h30 - 13h30',
					intitule: "Poème sur la santé mentale (Laura Ngoune)",
					image: "/assets/img/themes/Laura Ngoune.jpg",
					description: ["Laura Ngoune avocat au Barreau de France, Cameroun, Angleterre"]
				},
				{
					time: '13h45 - 14h45',
					intitule: "Coupure du ruban et visite des stands"
				},
				{
					time: '14h45',
					intitule: "Début de vente dans la salle Tam Tam"
				},
				{
					time: '14h45 - 15h30',
					intitule: "Interview et photos"
				},
				{
					time: '15h30',
					intitule: "Ouverture du buffet dans la salle Moliko"
				}
			]
		},
		{
			day: "day-two",
			events: [
				{
					time: "10h00 - 22h00",
					intitule: "Vente autour des stands"
				},
				{
					time: "10h00 - 12h00",
					intitule: "Formation sur la conformité de l'étiquetage au Cameroun et l'obligation d'avoir un certificat de conformité de l'ANOR pour les produits soumis aux normes d'application obligatoires",
					description: [
						"Atelier animé par : LAURE NANA - qualiticienne diplômée de l'ISA, Expert en sécurité sanitaire des aliments et en qualité dans la grande distribution",
						"CEO de Quality Pro Consulting"
					],
					image: "/assets/img/themes/etiquetage.jpg"
				},
				{
					time: '12h00 - 14h00',
					intitule: "L’agriculture : techniques frugales",
					image: '/assets/img/themes/techniques_frugales.jpg'
				},
				{
					time: '14h00 - 16h00',
					intitule: "La santé par la peau : reconnaitre son type de peau et prodiguer les soins naturels adaptés",
					image: '/assets/img/themes/sante_peau.jpg'
				},
				{
					time: '16h00 - 18h00',
					intitule: "Alimentation et performance en entreprise",
					image: '/assets/img/themes/alimentation_performance.jpg'
				}
			]
		},
		{
			day: 'day-three',
			events: [
				{
					time: '6h00 - 6h30',
					intitule: "Rassemblement des coureurs, du fan club et de l'équipe"
				},
				{
					time: '7h00 - 9h00',
					intitule: "Début et déroulement du Marathon",
					image: '/assets/img/themes/marathon.jpg'
				},
				{
					time: '9h00 - 9h30',
					intitule: "Fin du marathon et remise des lots aux gagnants"
				},
				{
					time: '10h00 - 12h00',
					intitule: "Live makeup : Comment faire un look de tous les jours en 15 min",
					image: '/assets/img/themes/live_makeup.jpg',
					description: ["Par Bold Makeup"]
				},
				{
					time: '10h00 - 22h00',
					intitule: "Vente autour des stands"
				},
				{
					time: '12h30 - 14h30',
					intitule: "Boulimie et Anorexie : on en parle",
					image: '/assets/img/themes/Boulemie.jpg'
				},
				/*{
					time: '14h30 - 20h00',
					intitule: "Live Cooking Show + Diner privé",
					image: "/assets/img/themes/ndockbidi.jpg",
					description: ["Avec NdockBidi au parking près de la piscine"]
				},*/
				{
					time: '16h00 - 18h30',
					intitule: "Cancer et Alimentation",
					image: "/assets/img/themes/Talk ADNC .jpg"
				}
			]
		},
		{
			day: 'day-four',
			events: [
				{
					time: '10h00 - 22h00',
					intitule: "Ouverture du salon et vente autour des stands"
				},
				{
					time: '12h00 - 14h00',
					intitule: "Reconnaitre un produit bio : techniques frugales",
					image: "/assets/img/themes/Dimanche Talk.jpg"
				},
				{
					time: '14h00 - 16h00',
					intitule: "Le tourisme du bien-être, la nouvelle formule santé",
					image: "/assets/img/themes/tourisme.jpg"
				},
				{
					time: '18h00 - 20h00',
					intitule: "Live skin care",
					image: "/assets/img/themes/activities.jpg"
				},
				{
					time: '20h00 - 20h30',
					intitule: "Mot de fin de la promotrice, interview et photo"
				},
				{
					time: '20h30 - 22h00',
					intitule: "Vente autour des stands et concert"
				},
			]
		}
	]

	function handleClick(link) {
		const newEvents = programs.filter(p => p.day === link)
		setEvents(newEvents)
	}

    return (
        <div class="event fix pt-120 pb-120">
			<div class="row justify-content-center">
				<div class="col-xl-6 col-lg-8">
					<div class="section-title text-center mb-80">
						<span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Programme</span>
						<h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Un florilège de thématiques ouvertes à tous</h2>
					</div>
				</div>
			</div>
            <div class="container">
				<div class="row">
					<div class="col-lg-12 ">				
						<nav class="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
							<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
								<a class="nav-item nav-link active show" 
									id="day-one" data-toggle="tab" 
									href="#day-one" role="tab" 
									aria-selected="true"
									onClick={() => handleClick("day-one")}
								>
									<img src="/assets/img/t-icon.png" alt="img" class="drk-icon" />		
									<img src="/assets/img/t-w-icon1.png" alt="img" class="lgt-icon" />  
									<div class="nav-content">
										<strong>1ère journée</strong>
										<span>Jeudi 7 Novembre 2024</span>
									</div>
								</a>
								<a class="nav-item nav-link" 
									id="day-two" data-toggle="tab" 
									href="#day-two" role="tab" 
									aria-selected="false"
									onClick={() => handleClick("day-two")}
								>
									<img src="/assets/img/t-icon.png" alt="img" class="drk-icon" />		
									<img src="/assets/img/t-w-icon1.png" alt="img" class="lgt-icon" /> 
									<div class="nav-content">
										<strong>2e journée</strong>
										<span>Vendredi 8 Novembre 2024</span>
									</div>
								</a>
								<a class="nav-item nav-link" 
									id="day-three" data-toggle="tab" 
									href="#day-three" role="tab" 
									aria-selected="false"
									onClick={() => handleClick("day-three")}
								>
									<img src="/assets/img/t-icon.png" alt="img" class="drk-icon" />		
									<img src="/assets/img/t-w-icon1.png" alt="img" class="lgt-icon" /> 
									<div class="nav-content">
										<strong>3e journée</strong>
										<span>Samedi 9 Novembre 2024</span>
									</div>
								</a>
								<a class="nav-item nav-link" 
									id="day-four" data-toggle="tab" 
									href="#day-four" role="tab" 
									aria-selected="false"
									onClick={() => handleClick("day-four")}
								>
									<img src="/assets/img/t-icon.png" alt="img" class="drk-icon" />		
									<img src="/assets/img/t-w-icon1.png" alt="img" class="lgt-icon" /> 
									<div class="nav-content">
										<strong>4e journée</strong>
										<span>Dimanche 10 Novembre 2024</span>
									</div>
								</a>
							</div>
						</nav>
						<div class="tab-content py-3 px-3 px-sm-0 wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s" id="nav-tabContent">
							{events.map((p, i) => (
								<div key={i} class="tab-pane fade active show" id={p.day} role="tabpanel" aria-labelledby={p.day}>
									
									{
										p.events.map((event, index) => (
											<div class="row mb-30" key={index}>
												{/* <div class="col-lg-2">
													<div class="user">
														<div class="title">  
															<img src="/assets/img/event_avatar_1.png" alt="img" />							  
															<h5>{event.speaker.nom}</h5>
															<p>{event.speaker.role}</p>
														</div>
														<ul>
															<li><i class="fal fa-coffee"></i> Coffe & Snacks</li>
															<li><i class="fal fa-video"></i> Video Streming</li>
														</ul>
													</div>
												</div> */}
												<div class="col-lg-10">
													<div class="event-list-content fix" style={{backgroundImage: `url(${event?.data?.image})`}}>
														<ul data-animation="fadeInUp animated" data-delay=".2s" style={{animationDelay: "0.2s"}} class="">
															<li><i class="fas fa-map-marker-alt"></i> Starland Hotel, Douala</li>
															<li><i class="far fa-clock"></i> {event.time}</li>
														</ul>
														<h4>{event.intitule}</h4>
														{/* <a href="/" class="btn mt-20 mr-10"><i class="far fa-ticket-alt"></i> Acheter un ticket</a>
														<a href="/" class="btn mt-20">En savoir plus</a> */}
														{event.description && event.description.map((desc, i) => (
															<p key={i} style={{fontWeight: '500', fontSize: 18}}>{desc}</p>
														))}
														{/* <div class="crical"><i class="fal fa-video"></i> </div> */}
														{event.image && <img src={event.image} alt={event.intitule} />}
													</div>
												</div>
											</div>
										))
									}
									
									{/* <div class="row mb-30">
									<div class="col-lg-2">
										<div class="user">
											<div class="title">  
												<img src="/assets/img/event_avatar_2.png" alt="img" />							  
												<h5>Kelian M. Bappe</h5>
												<p>youtubing</p>
											</div>
											<ul>
											<li><i class="fal fa-coffee"></i> Coffe & Snacks</li>
											<li><i class="fal fa-video"></i> Video Streming</li>
										</ul>
										</div>
									</div>
									<div class="col-lg-10">
										<div class="event-list-content fix">
											<ul data-animation="fadeInUp animated" data-delay=".2s" style={{animationDelay: "0.2s"}} class="">
												<li><i class="fas fa-map-marker-alt"></i> Waterfront Hotel, London</li>
												<li><i class="far fa-clock"></i> 9.30 - 10.30 AM</li>
											</ul>
											<h2>Rokolo DJ Dancing 2019</h2>
											<p>In order to save time you have to break down the content strategy for the event or conference you are planning step by step. Creating this process from scratch will take the longest amount of time to build, but once you have content production foundation.</p>
											<a href="/" class="btn mt-20 mr-10"><i class="far fa-ticket-alt"></i> Buy Ticket</a>
											<a href="/" class="btn mt-20">Read More</a>
											<div class="crical"><i class="fal fa-magic"></i> </div>
										</div>
									</div>
									</div>
									
									<div class="row mb-30">
									<div class="col-lg-2">
										<div class="user">
											<div class="title">  
												<img src="/assets/img/event_avatar_3.png" alt="img" />							  
												<h5>Hiliniam Nelson</h5>
												<p>UX Deisgn</p>
											</div>
											<ul>
											<li><i class="fal fa-coffee"></i> Coffe & Snacks</li>
											<li><i class="fal fa-video"></i> Video Streming</li>
										</ul>
										</div>
									</div>
									<div class="col-lg-10">
										<div class="event-list-content fix">
											<ul data-animation="fadeInUp animated" data-delay=".2s" style={{animationDelay: "0.2s"}} class="">
												<li><i class="fas fa-map-marker-alt"></i> Waterfront Hotel, London</li>
												<li><i class="far fa-clock"></i>  9.30 - 10.30 AM</li>
											</ul>
											<h2>Google Youtube Stratigic Party</h2>
											<p>In order to save time you have to break down the content strategy for the event or conference you are planning step by step. Creating this process from scratch will take the longest amount of time to build, but once you have content production foundation.</p>
											<a href="/" class="btn mt-20 mr-10"><i class="far fa-ticket-alt"></i> Buy Ticket</a>
											<a href="/" class="btn mt-20">Read More</a>
											<div class="crical"><i class="far fa-cogs"></i> </div>
										</div>
									</div>
									</div>
									
									<div class="row mb-30">
									<div class="col-lg-2">
										<div class="user">
											<div class="title">  
												<img src="/assets/img/event_avatar_4.png" alt="img" />							  
												<h5>Kimjing J. Jalim</h5>
												<p>UX Deisgn</p>
											</div>
											<ul>
											<li><i class="fal fa-coffee"></i> Coffe & Snacks</li>
											<li><i class="fal fa-video"></i> Video Streming</li>
										</ul>
										</div>
									</div>
									<div class="col-lg-10">
										<div class="event-list-content fix">
											<ul data-animation="fadeInUp animated" data-delay=".2s" style={{animationDelay: "0.2s"}} class="">
												<li><i class="fas fa-map-marker-alt"></i> Waterfront Hotel, London</li>
												<li><i class="far fa-clock"></i>  9.30 - 10.30 AM</li>
											</ul>
											<h2>Intro Jiknim Jikis 2019</h2>
											<p>In order to save time you have to break down the content strategy for the event or conference you are planning step by step. Creating this process from scratch will take the longest amount of time to build, but once you have content production foundation.</p>
											<a href="/" class="btn mt-20 mr-10"><i class="far fa-ticket-alt"></i> Buy Ticket</a>
											<a href="/" class="btn mt-20">Read More</a>
											<div class="crical"><i class="fal fa-ban"></i></div>
										</div>
									</div>
									</div> */}
									
								</div>
							))}
						</div>
					</div>
					{/* <div class="col-lg-12 justify-content-center text-center">
						<a href="/" class="btn mt-20 mr-10">More Program  +</a>
					</div> */}
			   </div>
            </div>
        </div>
    )
}

export default ProgramsEvent
